import React from 'react';
import "./_headline.scss";
import { render } from 'storyblok-rich-text-react-renderer';

export default function Headline(props) {
  const { description, headline, subline, image, isMobile } = props;

  return (
    <div>
      {!isMobile && image != undefined && <img src={image} alt="hero-img" className="hero-image" />}
      <div className='component-container'>
        <div className="headline-container">
          <div className='page-headline'>{(typeof headline === 'string' || headline instanceof String) ? headline : render(headline)}</div>
          <div className='page-subline'>{(typeof subline === 'string' || subline instanceof String) ? subline : render(subline)}</div>
        </div>
        <div className='description-container'>
          <div className='page-description'>
            {render(description)}
          </div>
        </div>
      </div>
    </div>
  );
}
