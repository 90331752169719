import React from 'react';
import "./_mobile-menu.scss";
import MobileMenuItem from '../MobileMenuItem/MobileMenuItem';
import CloseIcon from '../../assets/CloseIcon';

export default function MobileMenu(props) {
  const { isOpen, setIsOpen, pages, basename } = props;

  return (
    <div className={`drawer-container ${isOpen ? 'drawer-container--isOpen' : ''}`}>
      <div className="drawer-content-container">
        <div className="drawer-close-icon"><CloseIcon onClick={() => setIsOpen(false)} /></div>
        <div className="mobile-menu-items-container">
          <MobileMenuItem key={"Home--1}"} text={"Home"} basename={basename} closePage={setIsOpen} />
          {pages.map((name, i) => {
            return <MobileMenuItem basename={basename} key={`${name}-${i}`} text={name} closePage={setIsOpen} />;
          })}
        </div>
      </div>

    </div >
  );
}