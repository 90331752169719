import React from 'react';
import { typeToColor } from '../../helpers';
import "./_category-cell.scss";

export default function CategoryCell(props) {
  const { type } = props;

  return (
    <td className='category-table-cell'>
      <div className='category-table-text'>
        <div className='work-circle-container'>
          <span className="work-circle" style={{ background: typeToColor[type] }} />
        </div>
        {type}
      </div>
    </td>
  );
}