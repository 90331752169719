import React from 'react';
import Headline from '../components/Headline/Headline';
import Sketch from '../components/Sketch/Sketch';
import { storyblokEditable } from '@storyblok/react';
import EventCard from '../components/EventCard/EventCard';

export default function SketchesPage(props) {
  const { blok, isMobile, isSingleColumn } = props;

  return (
    <div className="container" {...storyblokEditable(blok)} key={blok._uid}>
      <Headline
        description={blok.description}
        headline={blok.title}
        subline={""}
        isMobile={isMobile}
        image={blok.hero_image.filename}
      />
      {!isMobile && blok.body.map((item, i) => {
        return <Sketch
          key={`${item.title} - ${i}`}
          title={item.title}
          description={item.description}
          link={item.observable_link}
          isMobile={isMobile}
          isSingleColumn={isSingleColumn}
        />;
      })}
      {isMobile && blok.body.map((item, i) => {
        item.image = item.observable_image;
        return <EventCard
          key={`${item.title} - ${i}`}
          name={item.title} title={""}
          description={item.description}
          images={[item]}
          isMobile={isMobile}
          link={item.observable_link}
        />;
      })}
    </div>
  );
}