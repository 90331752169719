import React from 'react';
import "./_about-card.scss";
import { render } from 'storyblok-rich-text-react-renderer';

export default function AboutCard(props) {
  const { title, text } = props;
  return (
    <div className="about-container">
      <div className="about-title">{title}</div>
      <div className="about-text">{render(text)}</div>
    </div >
  );
}