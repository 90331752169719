import React from 'react';
import "./_mobile-footer.scss";

export default function MobileFooter(props) {
  const { scrollWidth, page, basename } = props;

  return (
    <div className='mobile-footer' style={page === "work" ? {
      width: "100%"
    } : {
      width: "calc(100% - " + scrollWidth + "px)"
    }}>
      <div className='mobile-footer-container' >
        <a href={`${basename}contact`} className='mobile-meeting-button'>Schedule A Meeting</a>
      </div>
    </div >
  );
}