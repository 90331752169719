import React from 'react';

export default function InstagramSymbol(props) {
  const { link, height, width, isHeader } = props;

  return (
    <a className="email-symbol" href={link} target="_blank" style={isHeader ? { marginLeft: "5px" } : { marginRight: "5px" }}>
      <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">

        <path d="M16.3748 3.24984C17.5342 3.25331 18.6451 3.71539 19.4648 4.53517C20.2846 5.35495 20.7467 6.46582 20.7502 7.62516V16.3748C20.7467 17.5342 20.2846 18.6451 19.4648 19.4648C18.6451 20.2846 17.5342 20.7467 16.3748 20.7502H7.62516C6.46582 20.7467 5.35495 20.2846 4.53517 19.4648C3.71539 18.6451 3.25331 17.5342 3.24984 16.3748V7.62516C3.25331 6.46582 3.71539 5.35495 4.53517 4.53517C5.35495 3.71539 6.46582 3.25331 7.62516 3.24984H16.3748ZM16.3748 1.5H7.62516C4.25625 1.5 1.5 4.25625 1.5 7.62516V16.3748C1.5 19.7437 4.25625 22.5 7.62516 22.5H16.3748C19.7437 22.5 22.5 19.7437 22.5 16.3748V7.62516C22.5 4.25625 19.7437 1.5 16.3748 1.5Z" fill="#332D44" />
        <path d="M17.6873 7.62521C17.4277 7.62521 17.174 7.54823 16.9581 7.40402C16.7423 7.2598 16.5741 7.05481 16.4747 6.81498C16.3754 6.57516 16.3494 6.31126 16.4 6.05666C16.4507 5.80206 16.5757 5.56819 16.7592 5.38464C16.9428 5.20108 17.1767 5.07608 17.4313 5.02543C17.6859 4.97479 17.9498 5.00078 18.1896 5.10012C18.4294 5.19946 18.6344 5.36769 18.7786 5.58353C18.9228 5.79937 18.9998 6.05312 18.9998 6.31271C19.0002 6.48518 18.9665 6.65602 18.9007 6.81542C18.8348 6.97483 18.7382 7.11967 18.6162 7.24162C18.4943 7.36357 18.3494 7.46023 18.19 7.52606C18.0306 7.59189 17.8598 7.62558 17.6873 7.62521Z" fill="#332D44" />
        <path d="M12 8.49984C12.6923 8.49984 13.369 8.70512 13.9446 9.08973C14.5202 9.47433 14.9688 10.021 15.2337 10.6605C15.4986 11.3001 15.5679 12.0039 15.4329 12.6828C15.2978 13.3618 14.9645 13.9855 14.475 14.475C13.9855 14.9645 13.3618 15.2978 12.6828 15.4329C12.0039 15.568 11.3001 15.4986 10.6605 15.2337C10.021 14.9688 9.47433 14.5202 9.08973 13.9446C8.70512 13.369 8.49984 12.6923 8.49984 12C8.50084 11.072 8.86992 10.1823 9.52611 9.52611C10.1823 8.86992 11.072 8.50084 12 8.49984ZM12 6.75C10.9616 6.75 9.94661 7.05791 9.08326 7.63478C8.2199 8.21166 7.54699 9.0316 7.14963 9.99091C6.75227 10.9502 6.64831 12.0058 6.85088 13.0242C7.05345 14.0426 7.55347 14.9781 8.28769 15.7123C9.02191 16.4465 9.95737 16.9466 10.9758 17.1491C11.9942 17.3517 13.0498 17.2477 14.0091 16.8504C14.9684 16.453 15.7883 15.7801 16.3652 14.9167C16.9421 14.0534 17.25 13.0384 17.25 12C17.25 10.6076 16.6969 9.27226 15.7123 8.28769C14.7277 7.30312 13.3924 6.75 12 6.75Z" fill="#332D44" />
      </svg>
    </a>
  );
};