import React from 'react';
import "./_styles.scss";

export default function EmployeeIcon(props) {
  const { svg } = props;

  return (
    <svg className="employee-icon" width="32" height="32">
      <image xlinkHref={svg} width="32" height="32" />
    </svg>
  );
};
