import React from 'react';
import "./_styles.scss";
import Headline from '../components/Headline/Headline';
import { storyblokEditable } from '@storyblok/react';

export default function LandingPage(props) {
  const { blok } = props;


  return (
    <div className="container" id='landing' style={{ padding: "0 4rem 3rem 4rem" }} {...storyblokEditable(blok)} key={blok._uid}>
      <div className="video-responsive">
        <iframe src={`https://player.vimeo.com/video/889915178?autoplay=1&muted=1&title=0&byline=0&portrait=0`} className="video-responsive-item"
          frameBorder="0"
          allow="autoplay; fullscreen" allowFullScreen title={"Landing-Video"}></iframe>
      </div>
      <Headline
        description={blok.description}
        headline={blok.title}
        subline={blok.label}
      />
    </div>
  );
}