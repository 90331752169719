import React from 'react';
import "./_mobile-menu-item.scss";

export default function MobileMenuItem(props) {
  const { text, closePage, basename } = props;

  const pathname = text === "Home" ? "landing" : text;

  return (
    <a className='menu-item-text' href={`${basename}${pathname.toLowerCase()}`} onClick={() => { closePage(false); }}>{text === "Talks-Events" ? "Talks & Events" : text}</a>
  );
}