import React, { useState } from 'react';
import ArrowDown from '../assets/ArrowDown';
import CategoryCell from '../components/CategoryCell/CategoryCell';
import TypeCell from '../components/TypeCell/TypeCell';
import SortButton from '../components/SortButton/SortButton';
import { storyblokEditable } from '@storyblok/react';
import Star from '../assets/Star';
import colors from "../colors.module.scss";
import MobileYearCard from '../components/MobileYearCard/MobileYearCard';
import MobileWorkCard from '../components/MobileWorkCard/MobileWorkCard';

export default function WorkPage(props) {
  const { blok, isSingleColumn, handleModalChange } = props;
  const [sortRows, setSortRows] = useState({});
  const [categories, setCategories] = useState(['Finance', 'Data Story', 'Media', 'Nonprofit', 'Human Resource', 'Health Care', 'Other']);

  const years = [];

  // sort table body by year
  blok.body.sort(function (a, b) {
    return parseInt(b.year) - parseInt(a.year);
  });

  // sort by category
  let tableRows = blok.body.filter(function (item) {
    return categories.includes(item.category);
  });

  const sortKeys = Object.keys(sortRows);

  // if a column sort has been selected, sort by numbers or string based on the column name (year, project, etc)
  if (sortKeys.length !== 0) {
    const key = sortKeys[0];
    tableRows = tableRows.sort(function (a, b) {
      if (key === "year" && sortRows[key]) {
        return parseInt(a[key]) - parseInt(b[key]);
      } else if (key === "year" && sortRows[key]) {
        return parseInt(b[key]) - parseInt(a[key]);
      } else if (sortRows[key]) {
        return a[key].localeCompare(b[key]);
      } else {
        return b[key].localeCompare(a[key]);
      }
    });
  }

  // if its single column mode, ingore other sorts and sort by year
  if (isSingleColumn) {
    tableRows = blok.body.sort(function (a, b) {
      return parseInt(b.year) - parseInt(a.year);
    });
  }

  // handle sorting heirarchy by assigned column names true or false (to sort or not)
  const handleRowSorting = (name) => {
    if (sortRows.hasOwnProperty(name)) {
      setSortRows({ [name]: !sortRows[name] });
    } else if (sortKeys.length !== 0 && sortKeys[0] !== name && name === "year") {
      setSortRows({ [name]: false });
    } else {
      setSortRows({ [name]: true });
    }
  };

  // remove category name if its been deselected by user, add if it's not there
  const handleCategorySelection = (name) => {
    const temp = [...categories];
    if (temp.includes(name)) {
      const index = temp.indexOf(name);
      temp.splice(index, 1);
      setCategories(temp);
    } else {
      temp.push(name);
      setCategories(temp);
    }
  };

  function sortCategoryTypes() {
    const result = {};
    const items = blok.body;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (!result.hasOwnProperty(item.category)) {
        result[item.category] = [item];
      } else {
        result[item.category].push(item);
      }
    }
    return result;
  }

  const dataByCategories = sortCategoryTypes();
  let newCategories = [];
  if (dataByCategories) {
    newCategories = Object.keys(dataByCategories).sort(function (a, b) {
      return dataByCategories[b].length - dataByCategories[a].length;
    });
  }

  return (
    <div className="work-page-container" {...storyblokEditable(blok)} key={blok._uid}>
      {!isSingleColumn && <>
        <div className="sort-button-container-page">
          {newCategories.map((item, i) => { return <SortButton key={`${item}-${i}`} title={item} count={dataByCategories[item].length} isSelected={categories.includes(item)} onClick={handleCategorySelection} />; })}
        </div>
        <table className="work-table">
          <thead>
            <tr>
              <th
                className='table-header-cell'
                style={{ width: "0%" }}
              >
                <div className="year-arrow" onClick={() => handleRowSorting("year")}>
                  Year<ArrowDown />
                </div>
              </th>
              <th
                className='type-header-cell'
                style={{ width: "0%" }}
              />
              <th
                className='table-header-cell'
                style={{ width: "25%" }}
              >
                <div className="header-col-text" onClick={() => handleRowSorting("client")}>
                  Client
                </div>
              </th>
              <th
                className='table-header-cell'
                style={{ width: "30%" }}
              >
                <div className="header-col-text" onClick={() => handleRowSorting("project")}>
                  Project
                </div>
              </th>
              <th
                className='table-header-cell'
                style={{ width: "20%" }}
              >
                <div className="header-col-text" onClick={() => handleRowSorting("category")}>
                  Category
                </div>
              </th>
              <th className='type-header-cell' style={{ width: "30%" }} />
            </tr>
          </thead>
          <tbody>
            {tableRows.map((item, i) => {
              let client = item.client;
              if (client === "2n") {
                client = <>2<sup>n</sup></>;
              }
              if (!years.includes(item.year) || (sortKeys.length !== 0 && sortKeys[0] !== 'year')) {
                years.push(item.year);
                return <tr key={`${item.client} - ${i}`}>
                  <td className='year-table-cell'>{item.year}</td>
                  <td className='year-table-cell' title={item.award_name||''}>{item.award && <Star />}</td>
                  <td className='client-table-cell'>{client}</td>
                  <td className='data-table-cell'>{item.project}</td>
                  <CategoryCell type={item.category} />
                  <TypeCell item={item} handleModalChange={handleModalChange} />
                </tr>;
              }
              return <tr key={`${item.client} - ${i}`}>
                <td className='year-table-cell'></td>
                <td className='year-table-cell' title={item.award_name||''}>{item.award && <Star award_name={item.award_name} />}</td>
                <td className='client-table-cell'>{client}</td>
                <td className='data-table-cell'>{item.project}</td>
                <CategoryCell type={item.category} />
                <TypeCell item={item} handleModalChange={handleModalChange} />
              </tr>;
            })}
          </tbody>
        </table>
      </>
      }
      {
        isSingleColumn && <>
          <div className='component-container' style={{ paddingBottom: "1.875rem", borderBottom: `3px solid ${colors.purple}` }}>
            <div className="headline-container">
              <div className='page-headline'>Our Work</div>
            </div>
          </div>
          {tableRows.map((item, i) => {
            if (!years.includes(item.year)) {
              years.push(item.year);
              return <div key={`${item.client} - ${i}`}>
                <MobileYearCard year={item.year} />
                <MobileWorkCard item={item} />
              </div>;
            }
            return <MobileWorkCard key={`${item.client} - ${i}`} item={item} />;
          })}
        </>
      }
    </div>
  );
}