import React from 'react';
import { typeToColor } from '../../helpers';
import "./_mobile-work-card.scss";

export default function MobileWorkCard(props) {
  const { item } = props;

  return (
    <div className='mobile-work-container'>
      <div className='work-content-container'>
        <div className='mobile-circle-container'>
          <span className="work-circle" style={{ background: typeToColor[item.category] }} />
        </div>
        <div className='mobile-title-container'>
          <div className='mobile-client'>{item.client}</div>
          <div className='mobile-project'>{item.project}</div>
        </div>
      </div>
      <div className='flex-container'>
        <div className='mobile-links-container'>
          {item.link.url !== "" && item.is_compatible && <a
            href={item.link.url} target="_blank" className="type-box">Link</a>}
          {item.case_study.url !== "" && <a className="type-box" style={{ background: "#494D5F", color: "#CDCED1" }} href={item.case_study.url} target="_blank">Case Study</a>}
          {item.video.url !== "" && <a
            href={item.video.url} target="_blank" className="type-box">Video</a>}
        </div>
      </div>
    </div>
  );
}