import React from 'react';
import Headline from '../components/Headline/Headline';
import EventCard from '../components/EventCard/EventCard';
import { storyblokEditable } from '@storyblok/react';

export default function LecturesPage(props) {
  const { blok, isMobile } = props;

  return (
    <div className="container" {...storyblokEditable(blok)} key={blok._uid}>
      <Headline
        description={blok.description}
        headline={blok.title}
        subline={""}
        isMobile={isMobile}
        image={blok.hero_image.filename}
      />
      {blok.body.map((item, i) => { return <EventCard key={`${item.title} - ${i}`} name={item.title} title={item.location} description={item.description} images={item.images} isMobile={isMobile} link={item.link.url} />; })}
    </div>
  );
}