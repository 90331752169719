import React from 'react';
import AboutCard from '../components/AboutCard/AboutCard';
import EmployeeCard from '../components/EmployeeCard/EmployeeCard';
import Dot from '../assets/Dot';
import { render } from 'storyblok-rich-text-react-renderer';
import AboutImages from '../components/AboutImages/AboutImages';
import { storyblokEditable } from '@storyblok/react';

export default function AboutPage(props) {
  const { blok, isMobile, isSingleColumn } = props;

  const insert = (arr, index, newItem) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index)
  ];
  const temp = [...blok.alumnus];
  const amit = temp.shift();

  const sortedAlumni = temp.sort(function (a, b) {
    return a.name.localeCompare(b.name);
  });

  const alumniList = insert(sortedAlumni, 0, amit);

  return (
    <div className='about-page-container' {...storyblokEditable(blok)} key={blok._uid}>
      <AboutImages bloks={blok.image_carousel} isMobile={isMobile} />
      <div className='cards-container'>
        {blok.labels.map((item, i) => { return <AboutCard key={`${item.title} - ${i}`} title={item.title} text={item.description} />; })}
      </div>
      <div className='about-content-container'>
        <div className='employee-list-container'>
          {blok.body.map((item, i) => { return <EmployeeCard key={`${item.name} - ${i}`} index={i} name={item.name} title={item.title} description={item.description} icon={item.icon.filename} instagramLink={item.instagram_link.url} linkedInLink={item.linkedin_link.url} isSingleColumn={isSingleColumn} />; })}
        </div>
        {!isMobile && <div className="alumni-list-container">
          <div className="alumni-title">
            {render(blok.alumnus_title)}
          </div>
          <div className='alumni-list'>
            {alumniList.map((item, i) => {
              return <div key={`${item.name} - ${i}`} className='alumni-item'>
                <a href={item.link && item.link.url !== "" ? item.link.url : undefined} style={{ cursor: item.link && item.link.url !== "" ? "pointer" : undefined }} target="_blank" rel="noreferrer" className='alumni-name'>
                  {item.name}
                </a>
                {i + 1 !== blok.alumnus.length && <Dot />}
              </div>;
            })}
          </div>
        </div>}
      </div>
    </div>
  );
}