import React, { useEffect, useRef, useState } from 'react';
import "./_sketch.scss";
import { render } from 'storyblok-rich-text-react-renderer';
import { useInView } from 'react-intersection-observer';

export default function Sketch(props) {
  const { title, description, link, isMobile, isSingleColumn } = props;
  const { ref, inView } = useInView({
    triggerOnce: true,
    fallbackInView: false,
  });
  return <div className="sketch-container" ref={ref}>
    {isSingleColumn && <div className="sketch-title">{title}</div>}
    <div className='observable-container'>
      {inView ? <iframe width="100%" height="884" frameBorder="0" style={{ margin: 0 }}
        src={link}></iframe> : null}
    </div>
    {isSingleColumn && <div className="sketch-description">{render(description)}</div>}
    {!isSingleColumn && <div className="sketch-content-container">
      <div className="sketch-title">{title}</div>
      <div className="sketch-description">{render(description)}</div>
    </div>}
  </div>

    ;
}