import React from 'react';
import "./_mobile-year-card.scss";

export default function MobileYearCard(props) {
  const { year } = props;

  return (
    <div className='mobile-year-container'>
      {year}
    </div>
  );
}