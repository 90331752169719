import React from 'react';
import "./_styles.scss";

export default function ArrowDown(props) {
  const { } = props;

  return (
    <svg className="arrow-down" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.96967 7.84467C3.26256 7.55178 3.73744 7.55178 4.03033 7.84467L8 11.8143L11.9697 7.84467C12.2626 7.55178 12.7374 7.55178 13.0303 7.84467C13.3232 8.13756 13.3232 8.61244 13.0303 8.90533L8.53033 13.4053C8.23744 13.6982 7.76256 13.6982 7.46967 13.4053L2.96967 8.90533C2.67678 8.61244 2.67678 8.13756 2.96967 7.84467Z" fill="#242424" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8 2.375C8.41421 2.375 8.75 2.71079 8.75 3.125V12.25C8.75 12.6642 8.41421 13 8 13C7.58579 13 7.25 12.6642 7.25 12.25V3.125C7.25 2.71079 7.58579 2.375 8 2.375Z" fill="#242424" />
    </svg>);
}