import React from 'react';
import "./_footer.scss";

export default function Footer(props) {
  const { scrollWidth, page, basename } = props;

  return (
    <div className='footer' style={page === "work" ? {
      width: "100%"
    } : {
      width: "calc(100% - " + scrollWidth + "px)"
    }}>
      <div className='footer-container' >
        <a href={`${basename}contact`} className='meeting-button'>Schedule A Meeting</a>
      </div>
    </div >
  );
}