import React from 'react';
import "./_client-card.scss";

export default function ClientCard(props) {
  const { img, width } = props;

  return (
    <div style={{ width: width }} className='client-card-container'>
      <img className="client-img" src={img} alt="placeholder" />
    </div>
  );
}
