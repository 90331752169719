import React from 'react';
import Headline from '../components/Headline/Headline';
import ClientCard from '../components/ClientCard/ClientCard';
import { storyblokEditable } from '@storyblok/react';
import { remBreaks } from '../helpers';

export default function ClientsPage(props) {
  const { blok, isMobile, size, isSingleColumn } = props;

  const sizes = Object.keys(remBreaks).reverse();

  let responsiveMultiplier = 1;

  for (let i = 0; i < sizes.length; i++) {
    const width = sizes[i];
    if (size.width <= width) {
      responsiveMultiplier = remBreaks[width];
    }
  }

  // calculate card width, by size of screen, responsive layout, and rem size.
  const parent = document.getElementById('parent-cards-id');
  const padding = isMobile ? 32 : 128;
  const cardsPerRow = !isSingleColumn ? 5 : 2;
  const cardPadding = 2;
  let containerSize = size.width;
  if (parent != null) {
    containerSize = parent.clientWidth - (padding * responsiveMultiplier);
  }

  function generateClientList() {
    const result = [];
    const cardWidth = (containerSize - (cardPadding * cardsPerRow)) / cardsPerRow;
    for (let i = 0; i < blok.body.length; i++) {
      result.push(<ClientCard key={blok.body[i]._uid} img={blok.body[i].logo.filename} width={cardWidth} />);
    }
    return result;
  };

  return (
    <div id="parent-cards-id" className="container" {...storyblokEditable(blok)} key={blok._uid}>
      <Headline
        isMobile={isMobile}
        description={blok.description}
        headline={blok.title}
        subline={""}
        image={blok.hero_image.filename}
      />
      <div className="clients-container">
        {generateClientList()}
      </div>
    </div>
  );
}