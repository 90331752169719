import React from 'react';
import "./_event-card.scss";
import { render } from 'storyblok-rich-text-react-renderer';
import ImageCarousel from '../ImageCarousel/ImageCarousel';

export default function EventCard(props) {
  const { name, title, description, images, link, isMobile } = props;

  return (
    <div className='event-card-container'>
      {isMobile && <div className="event-title">
        <div className="event-name">{name}</div>
        <div className="event-label">{title}</div>
      </div>}
      <ImageCarousel bloks={images} />
      <div className='event-description-container'>
        {!isMobile && <div className="event-title">
          <div className="event-name">{name}</div>
          <div className="event-label">{title}</div>
        </div>}
        <div className='event-description'>
          {render(description)}
        </div>
        <div className="links-container">
          {link && <a className="social-link" href={link} target="_blank" rel="noreferrer">Link</a>}
        </div>
      </div>
    </div>
  );
}
