import React from 'react';
import "./_styles.scss";

export default function CloseIcon(props) {
  const { onClick } = props;

  return (
    <svg className="close-icon" onClick={() => onClick(false)} xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" viewBox="0 0 24 24" fill="none">
      <path d="M13.5909 11.9999L18.0441 7.54675C18.2554 7.33578 18.3743 7.04949 18.3745 6.75087C18.3748 6.45225 18.2564 6.16575 18.0455 5.95441C17.8345 5.74306 17.5482 5.62418 17.2496 5.62392C16.951 5.62366 16.6645 5.74203 16.4531 5.953L12 10.4061L7.54687 5.953C7.33553 5.74166 7.04888 5.62292 6.75 5.62292C6.45111 5.62292 6.16447 5.74166 5.95312 5.953C5.74178 6.16435 5.62305 6.45099 5.62305 6.74988C5.62305 7.04876 5.74178 7.33541 5.95312 7.54675L10.4062 11.9999L5.95312 16.453C5.74178 16.6643 5.62305 16.951 5.62305 17.2499C5.62305 17.5488 5.74178 17.8354 5.95312 18.0468C6.16447 18.2581 6.45111 18.3768 6.75 18.3768C7.04888 18.3768 7.33553 18.2581 7.54687 18.0468L12 13.5936L16.4531 18.0468C16.6645 18.2581 16.9511 18.3768 17.25 18.3768C17.5489 18.3768 17.8355 18.2581 18.0469 18.0468C18.2582 17.8354 18.3769 17.5488 18.3769 17.2499C18.3769 16.951 18.2582 16.6643 18.0469 16.453L13.5909 11.9999Z" fill="#242424" />
    </svg>
  );
};
