import React from 'react';
import "./_styles.scss";

export default function Dot(props) {
  const { } = props;

  return (
    <svg className="dot" width=".25rem" height=".25rem" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.66081 2.128C3.66081 3.024 2.92481 3.76 2.01281 3.76C1.10081 3.76 0.364813 3.024 0.364813 2.128C0.364813 1.216 1.10081 0.48 2.01281 0.48C2.92481 0.48 3.66081 1.216 3.66081 2.128Z" fill="#332D44" />
    </svg>
  );
};
