import React, { useState } from 'react';
import "./_mobile-header.scss";
import SmallLogo from '../../assets/SmallLogo';
import MenuIcon from '../../assets/MenuIcon';
import MobileMenu from '../MobileMenu/MobileMenu';

export default function MobileHeader(props) {
  const { page, pages, isMobile, basename } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mobile-header-container">
      <MobileMenu isOpen={isOpen} basename={basename} setIsOpen={setIsOpen} pages={pages} />
      <div className="center" onClick={() => setIsOpen(true)}><MenuIcon /></div>
      <div className="center mobile-header-text">{page === "landing" ? <div>2<sup style={{ textTransform: "none" }}>n</sup></div> : page === "talks-events" ? "Talks & Events" : page}</div>
      <div className="center"><SmallLogo isMobile={isMobile} basename={basename} /></div>
    </div >
  );
}