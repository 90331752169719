import React, { useState } from 'react';
import "./_image-carousel.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function ImageCarousel(props) {
  const { bloks, onClick } = props;

  const settings = {
    dots: true,
    infinite: false,
    afterChange: onClick
  };

  return (

    <div className='img-container'>
      <Slider {...settings}>
        {bloks.map((item, i) => {
          return <img className="images" key={item.image.id} src={item.image.filename} />;
        })}
      </Slider>
    </div >
  );
}