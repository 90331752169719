import React from 'react';
import "./_header.scss";
import SmallLogo from "../../assets/SmallLogo";
import Dot from '../../assets/Dot';
import InstagramSymbol from '../../assets/InstagramSymbol';
import LinkedInSymbol from '../../assets/LinkedInSymbol';

export default function Header(props) {
  const { page, pages, basename } = props;

  return (
    <div className="header-container">
      <SmallLogo basename={basename} />
      <div className='nav-button-container'>
        <div className='flex-container'>
          {pages.map((name, i) => {
            return <div key={`${name}-${i}`} className='flex-container'><a href={`${basename}${name.toLowerCase()}`} className={`nav-button ${page === name.toLowerCase() ? 'nav-button-active' : ""}`} >
              <div className="page-name">{name === "Talks-Events" ? "Talks & Events" : name}</div>
            </a>
              {name !== "Contact" && <Dot />}
            </div>;
          })}
        </div>
        <div className='flex-container'>
          <button className='email-button' onClick={() => window.location = 'mailto:info@two-n.com'}>info@two-n.com</button>
          <InstagramSymbol link={"https://www.instagram.com/2ngrams/"} height="1.5rem" width="1.5rem" isHeader={true} />
          <LinkedInSymbol link={"https://www.linkedin.com/company/two-n-inc"} height="1.5rem" width="1.5rem" isHeader={true} />
        </div>
      </div>
    </div >
  );
}