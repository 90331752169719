import React from 'react';
import "./_employee-card.scss";
import EmployeeIcon from '../../assets/EmployeeIcon';
import { render } from 'storyblok-rich-text-react-renderer';
import InstagramSymbol from '../../assets/InstagramSymbol';
import LinkedInSymbol from '../../assets/LinkedInSymbol';

export default function EmployeeCard(props) {
  const { name, title, description, icon, instagramLink, linkedInLink, index, isSingleColumn } = props;

  return (
    <div className="employee-container" style={!isSingleColumn ? index % 2 == 0 ? {
      paddingRight: "1.5%",
      width: "48.5%"
    } : {
      paddingLeft: "1.5%",
      width: "48.5%"
    } : { padding: 0 }}>
      <EmployeeIcon svg={icon} />
      <div className="employee-name">{name}</div>
      <div className="employee-title">{title}</div>
      <div className="employee-description">{render(description)}</div>
      <div className="links-container">
        {instagramLink != "" && <InstagramSymbol link={instagramLink} width="32" height="32" isHeader={false} />}
        {linkedInLink != "" && <LinkedInSymbol link={linkedInLink} width="32" height="32" isHeader={false} />}

      </div>
    </div >
  );
}