import React from 'react';

export default function SmallLogo(props) {
  const { isMobile, basename } = props;

  return (
    <a className="small-logo" style={isMobile ? { marginRight: "0" } : { marginRight: "1rem" }} href={`${basename}landing`}>
      <svg width="2rem" height="2rem" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="3" fill="#332D44" />
        <path d="M7.77344 24V21.6914L14.4414 13.3477C14.6992 13.0117 14.875 12.6992 14.9688 12.4102C15.0312 12.1367 15.0625 11.8789 15.0625 11.6367C15.0625 10.9961 14.8633 10.4375 14.4648 9.96094C14.0508 9.48438 13.4648 9.23828 12.707 9.22266C12.0352 9.22266 11.4766 9.4375 11.0312 9.86719C10.5703 10.2969 10.2969 10.9023 10.2109 11.6836H7.77344C7.83594 10.2617 8.3125 9.08594 9.20312 8.15625C10.1016 7.25 11.207 6.78906 12.5195 6.77344C13.9883 6.78906 15.1797 7.25781 16.0938 8.17969C17.0234 9.09375 17.4961 10.2539 17.5117 11.6602C17.5117 12.7852 17.168 13.7852 16.4805 14.6602L10.9844 21.5508H17.5117V24H7.77344Z" fill="#EEEEEE" />
        <path d="M19.4805 16V8.70156H20.95V9.475H20.9781C21.4609 8.89844 22.0938 8.61016 22.8766 8.61016C23.4813 8.61016 24.0344 8.82344 24.5359 9.25C25.0328 9.68125 25.293 10.3141 25.3164 11.1484V16H23.8469V11.6547C23.8469 11.1437 23.7109 10.7547 23.4391 10.4875C23.1719 10.2156 22.8273 10.0797 22.4055 10.0797C21.9836 10.0797 21.6367 10.2156 21.3648 10.4875C21.0883 10.7547 20.95 11.1437 20.95 11.6547V16H19.4805Z" fill="#EEEEEE" />
      </svg>
    </a>
  );
};
