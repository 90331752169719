import React from 'react';
import "./_video-modal.scss";
import "../../pages/_styles.scss";
import { render } from 'storyblok-rich-text-react-renderer';
import CloseIcon from '../../assets/CloseIcon';

export default function VideoModal(props) {
  const { handleModalChange, item } = props;

  const split = item.video.url.split("/");

  return (
    <div className="modal-container">
      <div className="video-responsive">
        <iframe src={`https://player.vimeo.com/video/${split[3]}?autoplay=1&loop=1&autopause=0`} className="video-responsive-item"
          frameBorder="0"
          allow="autoplay; fullscreen" allowFullScreen title={item.project}></iframe>
      </div>
      <div className='modal-description-container'>
        <div className="modal-header">
          <div className="modal-title">
            <div className="modal-name">{item.client}</div>
            <div className="modal-label">{`${item.project} – ${item.year}`}</div>
          </div>
          <CloseIcon onClick={handleModalChange} />
        </div>
        <div className='modal-description'>
          {render(item.description)}
        </div>
      </div>
    </div >
  );
}