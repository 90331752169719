import React from 'react';
import { typeToColor } from '../../helpers';
import colors from "../../colors.module.scss";
import "./_sort-button.scss";


export default function SortButton(props) {
  const { title, count, isSelected, onClick } = props;

  const color = isSelected ? typeToColor[title] : colors.grey2;

  const styles = {
    borderTop: `1px solid ${color}`,
    borderRight: `1px solid ${color}`,
    borderBottom: `1px solid ${color}`,
    borderLeft: `24px solid ${color}`
  };

  return (
    <div style={styles} className="sort-button-container" onClick={() => onClick(title)}>
      <div className="sort-button-text">
        {`${title} (${count})`}
      </div>
    </div>
  );
}