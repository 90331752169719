import React from 'react';
import Headline from '../components/Headline/Headline';
import { storyblokEditable } from '@storyblok/react';
import { InlineWidget } from 'react-calendly';

export default function ContactPage(props) {
  const { blok, isSingleColumn, isMobile, size } = props;
  const widgetMargin = size.width > 1425;
  return (
    <div className="container" {...storyblokEditable(blok)} key={blok._uid}>
      <Headline
        isMobile={isMobile}
        description={blok.description}
        headline={blok.title}
        subline={""}
        image={blok.hero_image.filename}
      />
      <div className={isSingleColumn ? 'column-contact-details-display' : 'contact-details-display'}>
        <div className={isSingleColumn ? 'column-team-photo-container' : "team-photo-container"} style={{ marginTop: widgetMargin ? "66px" : 0 }}>
          <img className="team-photo" src={blok.team_photo.filename} height="100%" width="100%" />
        </div>
        <InlineWidget url="https://calendly.com/natalie-wrm/30min" styles={isSingleColumn ? { width: "100%", paddingLeft: "0", height: "inherit" } : {
          width: "50%",
        }} />

      </div>

    </div >
  );
}
