import React, { useState } from 'react';
import "./_about-images.scss";
import { storyblokEditable } from '@storyblok/react';
import { render } from 'storyblok-rich-text-react-renderer';
import ImageCarousel from '../ImageCarousel/ImageCarousel';

export default function AboutImages(props) {
  const { bloks, description, isMobile } = props;
  const [imgIndex, setImgIndex] = useState(0);

  const handleIndexChange = (i) => {
    setImgIndex(i);
  };

  const selectedBlok = bloks[imgIndex];

  return (
    <div className="about-images-container"  {...storyblokEditable(selectedBlok)}>
      {isMobile && <div className='img-description-container'>
        <div className='img-description'>
          {render(description ? description : selectedBlok.description)}
        </div>
      </div>}
      <div className="img-title">
        <div className="img-name">{selectedBlok.name}</div>
        <div className="img-label">{selectedBlok.title}</div>
      </div>
      <div className='lg-img-container' >
        <ImageCarousel bloks={bloks} onClick={handleIndexChange} />
        {!isMobile && <div className='img-description-container'>
          <div className='img-description'>
            {render(description ? description : selectedBlok.description)}
          </div>
        </div>}
      </div>
    </div >
  );
}