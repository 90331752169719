import colors from "./colors.module.scss";

export const typeToColor = {
  "Data Story": colors.yellow,
  "Finance": colors.green,
  "Health Care": colors.blue,
  "Media": colors.fuchsia,
  "Nonprofit": colors.orange,
  "": colors.grey3,
  "Human Resource": colors.teal,
  "Other": colors.grey3,
};

// pixel: rem percentage
export const remBreaks = {
  1320: .90,
  900: .80,
  650: 1,
  420: .9
};