import React from 'react';
import "./_type-cell.scss";

export default function TypeCell(props) {
  const { item, handleModalChange } = props;

  return (
    <td className='type-cell'>
      {item.link.url !== "" && <a
        href={item.link.url} target="_blank" className="type-box">Link</a>}
      {item.case_study.url !== "" && <a className="type-box" style={{ background: "#494D5F", color: "#CDCED1" }} href={item.case_study.url} target="_blank">Case Study</a>}
      {item.video.url !== "" && <div onClick={() => handleModalChange(item)} className="type-box">Video</div>}
    </td>
  );
}