import React from 'react';
import "./_styles.scss";

export default function MenuIcon(props) {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="menu-icon" width="1.5rem" height="1.5rem" viewBox="0 0 24 24" fill="none">
      <path d="M3.75 7.5H20.25" stroke="#242424" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M3.75 12H20.25" stroke="#242424" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M3.75 16.5H20.25" stroke="#242424" strokeMiterlimit="10" strokeLinecap="round" />
    </svg>);
}